<template>
  <div>
    <b-card class="text-center p-3">
      <b-spinner />
    </b-card>
  </div>
</template>
<script setup>
import { BSpinner, BCard } from 'bootstrap-vue'
import { onMounted, getCurrentInstance } from 'vue'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import afterLogin from './afterLogin'

// eslint-disable-next-line no-unused-vars
const root = getCurrentInstance().proxy.$root
onMounted(() => {
  useJwt.loginWithSSoSmal({
    token: router.currentRoute.query.code,
  }).then(response => {
    console.log(response)
    afterLogin(response, root)
    if (window.opener) {
      window.opener.location.reload()
      window.close()
    }
  })
})
</script>
